import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Oral2Page: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const father_and_daughter_brushing_teeth_in_bathroom = data.father_and_daughter_brushing_teeth_in_bathroom?.childImageSharp?.fluid
  const close_up_of_woman_using_dental_floss_from_pack_in_bathroom =data.close_up_of_woman_using_dental_floss_from_pack_in_bathroom?.childImageSharp?.fluid


  return (
    <Layout location={location}>
      <Meta site={meta} title="Oral Surgery" />
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              ORAL & MAXILLOFACIAL SURGERY MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Oral Surgery
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of oral surgeries to help you achieve a healthy mouth and the perfect smile. Browse the page below to learn more. It’s easy to schedule your appointment with us.</p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <button className="btn-rounded"> Learn More </button>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
          <div className="col-lg-6">
              <div className="col-md-6">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improved Appearance</h3>
                <p>A healthy appearance is paramount to proper development and acceptance in social settings. With oral surgery you can improve the appearance of your teeth and gums making you more attractive.</p>
              </div>
              <div className="col-md-6">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improved Speech</h3>
                <p>Oral surgery can fix certain types of speech impediments leading to better speech and improved communication.</p>
              </div>
                <div className="col-md-6">
                  <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                  <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                  <p>Imagine being able to to talk, laugh, smile, and let people see your teeth with out feeling embarrassed. That is sure to make you feel good about yourself!</p>
                </div>
            </div>
            <div className="col-lg-6">
              <Img fluid = {father_and_daughter_brushing_teeth_in_bathroom} />
            </div>
          </Container>
          <Container>
            <div className =" col-lg-12"><p></p></div>
          </Container>
          <Container>
          <div className="col-lg-6">
            <Img fluid = {close_up_of_woman_using_dental_floss_from_pack_in_bathroom} />
          </div>
          <div className = "col-lg-6">
            <div>
              <i className="fa fa-bolt" aria-hidden="true"></i>
              <h3 className ="section-1-header">Improves Overall Dental Health</h3>
              <p>Eliminating problems in your mouth will let you keep your teeth and gums healthier for longer. You will feel good about putting in the extra effort to maintain your smile.</p>
            </div>
            <div>
              <i className="fa fa-diamond" aria-hidden="true"></i>
              <h3 className ="section-1-header">Enjoy Eating Again</h3>
              <p>When you have a full set of teeth and a mouth that is free of problems, eating and drinking become much easier.</p>
            </div>
            <div>
              <i className="fa fa-bookmark-o" aria-hidden="true"></i>
              <h3 className ="section-1-header">Improve Your Social Life</h3>
              <p>Fixing an unsightly or painful issue with your mouth can bring you more than just relief and health. When you smile confidently at others they will interact with you in a more positive manner.</p>
            </div>
          </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="the dentists at crossroad"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                STOP THE PAIN!
                </p>
                <h3 className="text-light">
                Don't miss out on another good day because you haven't taken care of your mouth!
                </h3>
                <p className="hero-text text-light">
                Dr. Vogt can help you to not have pain and discomfort in your mouth. Whether the solution is surgery or something else, don’t miss your chance to get it fixed right away. Call TODAY!
                </p>
                <a href="tel:3176439434" className="text-left"><button className="btn-rounded"><i className="fa fa-phone"></i> (317) 643-9434 </button></a>
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">DENTAL SURGERY DETAILS</p>
              <h2 className ="section-3-header">What is Oral Surgery?</h2>
             <p>Oral surgery is the treatment of oral health issues by performing surgery on the tissues of your mouth, including your teeth and gums. Oral surgery requires local anesthesia and sometimes sedation. For more information on<Link to="/services/sedation-dentistry"> sedation dentistry click here.</Link></p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Oral Surgery Right For You?</h3>
                <p>You think you need oral surgery, maybe you’re nervous and you’re unsure if it’s the right move for you. We understand and we’re here to help you come to the right decision. The pain and discomfort caused by an abnormality in your teeth or gums can keep you from living a normal life. If you are suffering from disease or an injury that has affected your mouth and you would like to get it fixed you may be a candidate for surgery. Dr. Vogt will consult you every step of the way. Not every problem needs surgery to fix, we will make sure you know all of your options before committing to this one. </p>
                <p>After surgery, we will provide you with the information that you need to recover quickly and get back to enjoying the good things in life again like food and smiling. </p>
                <p>Imagine your self without the pain and discomfort you have now. We can help you get there and Dr. Vogt will be with you every step of the way. Just give us a call to schedule a consultation.</p>
                <h3>Stop suffering from pain and discomfort.</h3>
                <p>Imagine your self without the pain and discomfort you have now. We can help you get there and Dr. Vogt will be with you every step of the way. Just give us a call to schedule a consultation.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Oral Surgeries Offered To Our Patients</h3>
                <p>Dr. Vogt is trained in several procedures. He can help you with:</p>
                <ul>
                  <li>extractions</li>
                  <li>bone grafts</li>
                  <li>gum grafts</li>
                  <li><Link to ="implant-dentistry">dental implants</Link></li>
                  <li>wisdom tooth removal</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Oral Surgery Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <a href="tel:3176439434">317-643-9434</a>.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default Oral2Page

export const query = graphql`
  query Oral2PageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    father_and_daughter_brushing_teeth_in_bathroom: file(name: { eq: "young-smiling-woman-sitting-in-cafe" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    close_up_of_woman_using_dental_floss_from_pack_in_bathroom: file(name: { eq: "smiling-young-african-ameircan-man-with-hat" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
